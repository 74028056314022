const jobs = [
  { id: 'Senior-Engineer',
    icon: "/images/logos/gt-logo.jpeg",
    title: 'Senior Engineer - Backend / DevOps Technologies',
    location: 'Colombo, Western Province, Sri Lanka (Remote)',
    subtitleOne: 'About the job',
    description: 'GoTradie is a Product-led Tech Startup based in Sydney, Australia. We are looking for a Senior Engineer in Colombo to join our growing engineering team.',
    headingOne: 'Highlights',
    bulletListOne: '<ul><li>We are running our core solution on AWS and follow an event-driven microservices based architecture.</li><li>We are working on the zero-touch operating principle and are running on 100% automated CICD, validation and operational activities. Our automation pipelines take less than 20 mins to promote the development code to production.</li><li>We are expanding our team in Colombo and we need your help to build the dream team.</li><li>We have used quite a few Open Source tools and we look forward to actively contribute back to the community.</li></ul>',
    headingTwo: 'The Right Candidate',
    bulletListTwo: '<ul><li>We have used Python and TypeScript. The idea candidate should have hands on experience in at least one these languages.</li><li>The ideal candidate should have hands on experience building solutions on AWS, or any other cloud provider platform.</li><li>We have always strived to build enterprise scale sustainable solutions. We really appreciate having an engineering mindset and the teamwork spirit.</li></li><li>We really like someone passionate about Open Source. You will be working closely with Open Source communities and contribute where possible.</li></ul>',
    headingThree: 'Perks',
    bulletListThree: '<ul><li>The salary will be paid in AUD</li><li>A very competitive salary awaits for the right candidate.</li><li>We are engineering a Product-led Startup. We hope you will have some hands on experience building a product and taking it to the masses.</li></ul>',
    conclusion: 'If you think that you are the right engineer, drop you CV, LinkedIn, GitHub Profile, Personal Blog or anything else that could showcase yourself to sajith@gotradie.com.au.'
  },
  { id: 'sales-intern',
    icon: "/images/logos/gt-logo.jpeg",
    title: 'Internship - Sales & Customer Success',
    location: 'Sydney, NSW, Australia (Hybrid)',
    subtitleOne: 'About the job',
    description: 'GoTradie is a Product-led Tech Startup based in Sydney, Australia. As we continue to expand our reach and grow our customer base, we are seeking a Sales Intern to join our dynamic team. This internship offers a unique opportunity to gain valuable real-world experience in the tech sector, work closely with our founding team, and potentially transition into a permanent paid role based on your performance.',
    headingOne: 'Key Responsibilities',
    bulletListOne: '<ul><li>Sales Support: Collaborate with the sales team to identify and target potential clients, assist with lead generation, and contribute to sales efforts.</li><li>Customer Success: Engage with customers to ensure their satisfaction, address inquiries, and assist with onboarding to our platform.</li><li>Documentation: Maintain accurate records of customer interactions and sales activities.</li></ul>',
    headingTwo: 'The Right Candidate',
    bulletListTwo: '<ul><li>Strong communication and interpersonal skills</li><li>Enthusiastic and motivated with a passion for technology and sales</li><li>Ability to work independently and as part of a team</li><li>Bonus point for experience with the trade & construction industry or ex-tradie</li></ul>',
    headingThree: 'Perks',
    bulletListThree: '<ul><li>Real-World Experience: Gain practical experience in sales, customer success, and tech industry dynamics.</li><li>Australian Tech Startup: Work with a forward-thinking team in a thriving startup environment.</li><li>Path to Permanent Role: Depending on your performance, there may be an opportunity to join our team in a permanent, paid capacity.</li></ul>',
    conclusion: 'If you think that you are the right person for the jon, drop you CV, LinkedIn or anything else that could showcase yourself to adam@gotradie.com.au.'
  },
];

module.exports = jobs;